
#DESIGNMAIN {
    background: white;
    position: relative;
    z-index: 5;
}
#DESIGN {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: linear-gradient(to top, rgb(255, 255, 255), rgba(74, 186, 241, 0.1));
    position: relative;
    z-index: 5;
}

.ideal .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-left: 84px;
    padding-right: 84px;
}

.ideal .first-column-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ideal .second-column-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ideal img {
    margin-bottom: 40px;
}

.ideal .idealistic-separator {
    background: #0F3A5733;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
}

/* Mobile */
@media only screen and (max-width: 480px) {
    .ideal img {
        margin-bottom: 30px;
    }

    .ideal h1 {
        max-width: 300px;
    }

    .ideal .column {
        padding-left: 24px;
        padding-right: 24px;
    }

    .ideal p {
        max-width: 350px;
    }

    .ideal .idealistic-separator {
        display: none;
    }
    .first-column {
        padding-bottom: 0px !important;
    }

}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
    .ideal h1 {
        max-width: 300px;
    }

    .ideal p {
        max-width: 400px;
    }
    
    .ideal .column {
        padding-left: 32px;
        padding-right: 32px;
    }

    .ideal .idealistic-separator {
        display: none;
    }
    .first-column {
        padding-bottom: 0px !important;
    }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {
    .ideal h1 {
        max-width: 300px;
    }

    .ideal p {
        max-width: 400px;
    }

    .ideal .column {
        padding-left: 42px;
        padding-right: 42px;
    }

    .ideal .idealistic-separator {
        display: none;
    }

    .first-column {
        padding-bottom: 0px !important;
    }
}

/* Large screens */
@media only screen and (min-width: 1025px) and (max-width:1200px) {
    #DESIGN {
        flex-direction: row;
        align-items: flex-start;
    }

    .ideal .column {
        padding-left: 84px;
        padding-right: 84px;
    }

    .ideal .first-column-img {
        flex-direction: column;
        justify-content: flex-end;
        flex:0 0 332px;
        margin-bottom:32px;
    }
    .ideal .second-column-img {
        flex-direction: column;
        justify-content: flex-end;
        flex:0 0 364px;
    }

    .ideal p {
        max-width: 630px;
    }

    .ideal h1 {
        width: 400px;
    }
}

/* Extra large screens */
@media only screen and (min-width: 1201px) {
    #DESIGN {
        flex-direction: row;
        align-items: flex-start;
    }

    .ideal .column {
        padding-left: 84px;
        padding-right: 84px;
    }

    .ideal .first-column-img {
        flex-direction: column;
        justify-content: flex-end;
        flex:0 0 332px;
        margin-bottom:32px;
    }
    .ideal .second-column-img {
        flex-direction: column;
        justify-content: flex-end;
        flex:0 0 364px;
    }

    .ideal p {
        max-width: 630px;
    }

    .ideal h1 {
        width: 400px;
    }
}
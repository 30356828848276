#DESIGNMAIN {
  z-index: 5;
  background: #fff;
  position: relative;
}

#DESIGN {
  z-index: 5;
  background: linear-gradient(to top, #fff, #4abaf11a);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  position: relative;
}

.ideal .column {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 84px;
  padding-right: 84px;
  display: flex;
}

.ideal .first-column-img, .ideal .second-column-img {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ideal img {
  margin-bottom: 40px;
}

.ideal .idealistic-separator {
  width: 1px;
  background: #0f3a5733;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media only screen and (max-width: 480px) {
  .ideal img {
    margin-bottom: 30px;
  }

  .ideal h1 {
    max-width: 300px;
  }

  .ideal .column {
    padding-left: 24px;
    padding-right: 24px;
  }

  .ideal p {
    max-width: 350px;
  }

  .ideal .idealistic-separator {
    display: none;
  }

  .first-column {
    padding-bottom: 0 !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .ideal h1 {
    max-width: 300px;
  }

  .ideal p {
    max-width: 400px;
  }

  .ideal .column {
    padding-left: 32px;
    padding-right: 32px;
  }

  .ideal .idealistic-separator {
    display: none;
  }

  .first-column {
    padding-bottom: 0 !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .ideal h1 {
    max-width: 300px;
  }

  .ideal p {
    max-width: 400px;
  }

  .ideal .column {
    padding-left: 42px;
    padding-right: 42px;
  }

  .ideal .idealistic-separator {
    display: none;
  }

  .first-column {
    padding-bottom: 0 !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  #DESIGN {
    flex-direction: row;
    align-items: flex-start;
  }

  .ideal .column {
    padding-left: 84px;
    padding-right: 84px;
  }

  .ideal .first-column-img {
    flex-direction: column;
    flex: 0 0 332px;
    justify-content: flex-end;
    margin-bottom: 32px;
  }

  .ideal .second-column-img {
    flex-direction: column;
    flex: 0 0 364px;
    justify-content: flex-end;
  }

  .ideal p {
    max-width: 630px;
  }

  .ideal h1 {
    width: 400px;
  }
}

@media only screen and (min-width: 1201px) {
  #DESIGN {
    flex-direction: row;
    align-items: flex-start;
  }

  .ideal .column {
    padding-left: 84px;
    padding-right: 84px;
  }

  .ideal .first-column-img {
    flex-direction: column;
    flex: 0 0 332px;
    justify-content: flex-end;
    margin-bottom: 32px;
  }

  .ideal .second-column-img {
    flex-direction: column;
    flex: 0 0 364px;
    justify-content: flex-end;
  }

  .ideal p {
    max-width: 630px;
  }

  .ideal h1 {
    width: 400px;
  }
}

/*# sourceMappingURL=index.eeeac25f.css.map */
